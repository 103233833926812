import { React, useEffect } from "react"
import { connect } from "react-redux"
import { useToast, Container } from "@chakra-ui/react"
import SEO from "../../components/SEO"

import SheetHeader from "../../components/SheetHeader"
import SheetRows from "../../components/SheetRows"
import StatusTravel from "../../components/StatusTravel"
import SwitchDetails from "../../components/SwitchDetails"
import SheetSummary from "../../components/SheetSummary"

import { isFactoryResetUpdate } from "../../redux/actions/app"
import { messageFromProjectDelete } from "../../redux/actions/project"
import { messageFromAppDelete } from "../../redux/actions/app"

const PageControlSheet = ({
  project,
  messageFromProject,
  isDebugOn,
  messageFromApp,
  isFactoryReset,
  dispatch,
}) => {
  //=================================================================
  const { params } = project

  const toast = useToast()

  useEffect(() => {
    if (messageFromProject !== "") {
      const title = messageFromProject.split(": ")[0]
      const description = messageFromProject.substring(
        messageFromProject.indexOf(":") + 1
      )
      const status = title.toLowerCase()
      toast({
        title,
        description,
        status: status ? status : "info",
        duration: 9000,
        isClosable: true,
      })
      dispatch(messageFromProjectDelete())
    }
    if (messageFromApp !== "") {
      const title = messageFromApp.split(": ")[0]
      const description = messageFromApp.substring(
        messageFromApp.indexOf(":") + 1
      )
      toast({
        title,
        description,
        status: "error",
        duration: isDebugOn ? 100 : 30000,
        isClosable: true,
      })
      dispatch(messageFromAppDelete())
    }
  }, [messageFromProject, messageFromApp, toast, isDebugOn, dispatch])

  useEffect(() => {
    if (isFactoryReset) {
      const payload = { isFactoryReset: false }
      dispatch(isFactoryResetUpdate(payload))
      window.location.reload()
    }
  }, [isFactoryReset, dispatch])

  //=================================================================
  return (
    // <Container maxW="1600px" px={0} minH="640px">
    <Container px={0} minH="640px">
      <SEO
        title="Status Sheet | THE PROJECT STATUS"
        urlPage="/"
        description="Replace any spreadsheet with a status report that is supporting proper project control."
      ></SEO>
      <SheetSummary />
      <SwitchDetails />
      <StatusTravel />
      <SheetHeader params={params} />
      <SheetRows />
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    project: state.project,
    messageFromApp: state.app.messageFromApp,
    isDebugOn: state.app.isDebugOn,
    messageFromProject: state.project.messageFromProject,
    isResetForm: state.project.isResetForm,
    isFactoryReset: state.settings.isFactoryReset,
  }
}

export default connect(mapStateToProps)(PageControlSheet)
