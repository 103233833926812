import { ROOT } from "../../const/globals"

/**
 * (c) Ulrich Anders
 *
 * @param {*} nodes
 * @param {*} nId
 * @returns {}
 */
export function nodesTreeDepsCircularCheck(
  nodes,
  nId = ROOT,
  seenAlready = []
) {
  let isCircular = false

  // console.log({
  //   nId,
  //   seenAlready: [...seenAlready].join(", "),
  //   precedents: [...nodes[nId].precedents].join(", "),
  // })
  if (seenAlready.length > 0 && seenAlready.indexOf(nId) > -1) {
    return true
  } else {
    seenAlready.push(nId)

    if (nodes[nId].precedents.length > 0) {
      nodes[nId].precedents.forEach((precedent) => {
        // console.log({ nId, precedent })
        isCircular =
          isCircular ||
          nodesTreeDepsCircularCheck(nodes, precedent, seenAlready)
      })
    }
  }
  return isCircular
}
