import React, { useState } from "react"
import { connect } from "react-redux"
import { chakra, Button, Box, Input, Flex, Text } from "@chakra-ui/react"
import { RiUploadFill } from "react-icons/ri"
import StatusUpload from "./styled/StatusUpload"
import { VERSION_API } from "../../const/versions"
import { shortHashGen } from "../../functions/utils/hashes"
import { projectUpload } from "../../redux/actions/project"

const ParamsUpload = ({ dispatch }) => {
  //=============================================================
  const [messageInfo, setMessageInfo] = useState({
    isShown: false,
    isWarning: false,
    isError: false,
    message: "",
  })

  // console.log("new render: ", { messageInfo })

  const onChange = (event) => {
    const file = event.target.files[0]
    let warning = ""

    const fileReader = new FileReader()
    // this is a definition of the onloadend event.
    // It is executed only after the file is read, i.e. after fileReader.readAsText(file)
    fileReader.onloadend = () => {
      try {
        const json = JSON.parse(fileReader.result)
        // console.log("json")
        let canDispatch = true

        if (
          json.appName === undefined ||
          json.appName !== "THE PROJECT STATUS" ||
          json.project === undefined ||
          json.projectTitle === undefined ||
          json.paramsHash === undefined
        ) {
          // console.log("ERROR: Keys undefined.")
          setMessageInfo((previous) => ({
            ...previous,
            isError: true,
            message:
              "ERROR: File or parameters are corrupted. Parameters were not taken over.",
          }))
          canDispatch = false
        } else {
          let paramsHash = shortHashGen(
            JSON.stringify({ project: json.project })
          )
          if (paramsHash !== json.paramsHash) {
            // console.log("ERROR: Hashes don't match.")
            setMessageInfo((previous) => ({
              ...previous,
              isError: true,
              message:
                "ERROR: File or parameters are corrupted. Parameters were not taken over.",
            }))
            canDispatch = false
          } else if (
            isNaN(parseInt(json.versionAPI)) ||
            json.versionAPI > parseInt(VERSION_API)
          ) {
            // console.log("ERROR: API version too high.")
            setMessageInfo((previous) => ({
              ...previous,
              isError: true,
              message:
                "ERROR: File or parameters are corrupted. Parameters were not taken over.",
            }))
            canDispatch = false
          } else if (parseInt(json.versionAPI) < parseInt(VERSION_API)) {
            // console.log("WARING: API version is lower than current version.")
            canDispatch = false
            setMessageInfo((previous) => ({
              ...previous,
              isError: true,
              message:
                "ERROR: Parameter are from outdated version. Parameters were not taken over.",
            }))
          }

          if (canDispatch) {
            // console.log("dispatch")
            const payload = { project: json.project, warning }
            dispatch(projectUpload(payload))
          } else {
            // console.log("no dispatch")
          }
        }
      } catch (e) {
        // console.log("ERROR: JSON syntax.")
        setMessageInfo((previous) => ({ ...previous, isError: true }))
      }
    }

    fileReader.readAsText(file)

    // State is only updated if done like this: isShown: !previous.isShown
    setMessageInfo((previous) => ({ ...previous, isShown: !previous.isShown }))
    setTimeout(() => {
      setMessageInfo((previous) => ({
        isShown: !previous.isShown,
        isError: false,
        isWarning: false,
        message: "",
      }))
    }, 10000)
  }

  return (
    <>
      <Button type="button" w="120px" mx={1} outline="none">
        <Box outline="none">
          <chakra.label htmlFor="filePicker" outline="none">
            <Flex align="center">
              <Box position="relative" top="-4px" left="4px">
                <RiUploadFill />
              </Box>
              <Text ml={2}>UPLOAD</Text>
            </Flex>
          </chakra.label>

          <Input
            id="filePicker"
            type="file"
            onChange={onChange}
            display="none"
            outline="none"
          />
        </Box>
      </Button>

      {messageInfo.isShown && (
        <StatusUpload
          isShown={messageInfo.isShown}
          isError={messageInfo.isError}
          isWarning={messageInfo.isWarning}
          message={messageInfo.message}
        />
      )}
    </>
  )
}

export default connect()(ParamsUpload)
