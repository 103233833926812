import React, { useRef } from "react"
import { connect } from "react-redux"
import {
  chakra,
  Flex,
  Box,
  Tooltip,
  useDisclosure,
  Button,
  AlertDialog,
  AlertDialogCloseButton,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react"
import {
  dateStatusLatestDelete,
  dateStatusCurrentUpdate,
} from "../../redux/actions/project"
import ISOToLoc from "../../functions/timeHandler/ISOToLoc"
import { RiDeleteBinLine } from "react-icons/ri"

const StatusTravel = ({ project, dispatch }) => {
  //=================================================================
  const { statusesList, statusCurrent } = project

  const isLatestLocked = project.statuses[statusesList[0]].setup.isStatusLocked

  //-----------------------------------------------------------------
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()

  //-----------------------------------------------------------------
  const onClick = (event) => {
    const payload = { statusCurrent: event.target.value }
    dispatch(dateStatusCurrentUpdate(payload))
  }

  const onClickDelete = (event) => {
    dispatch(dateStatusLatestDelete())
    onClose()
  }

  //-----------------------------------------------------------------
  const renderDatesStatusList = () => {
    const length = statusesList.length
    const renderDatesStatusList = statusesList.map((date, index) => {
      return (
        <chakra.button
          key={date}
          color={date === statusCurrent ? "inherit" : "grey.700"}
          fontWeight={date === statusCurrent ? "semibold" : "medium"}
          type="button"
          onClick={onClick}
          value={date}
          mr={4}
          outline="none"
        >
          {length - index - 1}: {ISOToLoc(date, "yyyy-MM-dd")}
        </chakra.button>
      )
    })
    return renderDatesStatusList
  }

  //=================================================================
  return (
    <>
      <Flex bg="blue.100" px={2} mb={2} justify="space-between">
        <Flex wrap="wrap">{renderDatesStatusList()}</Flex>
        {statusesList.length > 1 && !isLatestLocked && (
          <Tooltip
            label="This will delete the Latest Status Date. You can then take over the information from the Previous Status Date for setting up a New Status Date."
            placement="top-end"
            hasArrow
          >
            <Box
              as="button"
              type="button"
              onClick={onOpen}
              verticalAlign="baseline"
              h="1rem"
            >
              <RiDeleteBinLine />
            </Box>
          </Tooltip>
        )}
      </Flex>

      <AlertDialog
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>
            Confirm To Delete Latest Status Date
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to delete your latest Status Date? All
            information you have newly setup for this status date will be lost.
            But you can setup a New Status Date by taking over all information
            from the Previous Status Date.
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button color="white" bg="red" ml={3} onClick={onClickDelete}>
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    project: state.project,
  }
}

export default connect(mapStateToProps)(StatusTravel)
