import { createAction } from "@reduxjs/toolkit"

export const projectUpload = createAction("PROJECT_UPLOAD")
export const resetProject = createAction("RESET_PROJECT")
export const resetSheet = createAction("RESET_SHEET")

export const rowCreate = createAction("ROW_CREATE")
export const rowDelete = createAction("ROW_DELETE")

export const personAdd = createAction("PERSON_ADD")
export const personRemove = createAction("PERSON_REMOVE")
export const personUpdate = createAction("PERSON_UPDATE")

export const rowUpdate = createAction("ROW_UPDATE")
export const rowMoveIn = createAction("ROW_MOVE_IN")
export const rowMoveOut = createAction("ROW_MOVE_OUT")
export const rowMoveUpDown = createAction("ROW_MOVE_UP_DOWN")
export const rowFold = createAction("ROW_FOLD")
export const rowIgnore = createAction("ROW_IGNORE")
export const rowUnresolved = createAction("ROW_UNRESOLVED")
export const rowForecast = createAction("ROW_FORECAST")
export const rowQuality = createAction("ROW_QUALITY")

export const selectStatusUpdate = createAction("SELECT_STATUS_UPDATE")
export const dateStatusLatestDelete = createAction("DATE_STATUS_LAST_DELETE")
export const dateStatusCurrentUpdate = createAction(
  "DATE_STATUS_CURRENT_UPDATE"
)

export const isByWhenPinnedUpdate = createAction("BY_WHEN_PIN_UPDATE")
export const isFromWhenPinnedUpdate = createAction("FROM_WHEN_PIN_UPDATE")

export const isDetailsOnUpdate = createAction("IS_DETAILS_ON_UPDATE")
export const isNameFirstPreferredUpdate = createAction(
  "IS_NAME_FIRST_PREFERRED_UPDATE"
)
export const isHighlightedUpdate = createAction("IS_HIGHLIGHTED_UPDATE")

export const selectPersonUpdate = createAction("SELECT_PERSON_UPDATE")
export const selectPersonsUpdate = createAction("SELECT_PERSONS_UPDATE")
export const SelectRequestsUpdate = createAction("SELECT_REQUESTS_UPDATE")
export const selectRolesUpdate = createAction("SELECT_ROLES_UPDATE")

export const formRatiosUpdate = createAction("FORM_SATISFACTION_UPDATE")
export const formDatesUpdate = createAction("FORM_DATES_UPDATE")
export const formSetupUpdate = createAction("FORM_SETUP_UPDATE")
export const formParamsUpdate = createAction("FORM_PARAMS_UPDATE")
export const issueCheckUpdate = createAction("ISSUE_CHECK_UPDATE")
export const issueTLUpdate = createAction("ISSUE_TL_UPDATE")

export const messageFromProjectDelete = createAction("MESSAGE_FROM_X_DELETE")
