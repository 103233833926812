const appInitialState = {
  app: {
    isDebugOn: false,
    isExamOn: false,
    isFactoryReset: false,
    messageFromApp:
      "IMPORTANT: This is a preliminary TEST VERSION 0.14.x-alpha. For data handling and security, or all other issues please read through the info section: https://project-status.org/infos. The app works best on wide screens with an absolute minimum of 1280px. Below this, there might be distortions. The app is already well tested, but some errors may remain. Some features are pending and will be added very soon.",
  },
}

export default appInitialState
