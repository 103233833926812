import produce from "immer"
import cloneDeep from "lodash.clonedeep"
// import { deepDiff } from "../../functions/utils/deepDiff"

import merge from "lodash.merge"
import isWeekend from "date-fns/isWeekend"
import addBusinessDays from "date-fns/addBusinessDays"
import startOfDay from "date-fns/startOfDay"
import parseISO from "date-fns/parseISO"
import isAfter from "date-fns/isAfter"
import isBefore from "date-fns/isBefore"
import isSameDay from "date-fns/isSameDay"
import format from "date-fns/format"

import {
  projectUpload,
  resetProject,
  resetSheet,
  selectStatusUpdate,
  dateStatusCurrentUpdate,
  dateStatusLatestDelete,
  personAdd,
  personRemove,
  personUpdate,
  selectPersonUpdate,
  selectPersonsUpdate,
  SelectRequestsUpdate,
  selectRolesUpdate,
  rowCreate,
  rowDelete,
  rowMoveIn,
  rowMoveOut,
  rowMoveUpDown,
  rowFold,
  rowIgnore,
  rowUnresolved,
  rowUpdate,
  rowForecast,
  rowQuality,
  isByWhenPinnedUpdate,
  isHighlightedUpdate,
  isDetailsOnUpdate,
  isNameFirstPreferredUpdate,
  formRatiosUpdate,
  issueCheckUpdate,
  issueTLUpdate,
  formDatesUpdate,
  formSetupUpdate,
  formParamsUpdate,
  messageFromProjectDelete,
} from "../actions/project"

import { nodesDependenciesSet } from "../../functions/nodes/nodesDependencies"

import { projectInitialStateSet } from "../../redux/states/projectInitialState"

import nodeCreate from "../../functions/nodes/nodeCreate"
import { nodesTreeDepsCircularCheck } from "../../functions/nodes/nodesTreeDepsClean"
import nodeNewAddAfter from "../../functions/nodes/nodeNewAddAfter"
import nodesDelete from "../../functions/nodes/nodesDelete"
import nodesRowsGen from "../../functions/nodes/nodesRowsGen"
import { moveIn, moveOut, moveUpDown } from "../../functions/nodes/nodeMoves"
import { nodesTreeDownUp } from "../../functions/nodes/nodesAccumulations2"

import personCreate from "../../functions/persons/personCreate"
import {
  personRemoveFromNodes,
  personRemoveFromSetup,
} from "../../functions/persons/personRemoveFromX"
import {
  namesGen,
  nameDisplayShortGen,
  nameDisplayLongGen,
  personsDisplayNameGen,
} from "../../functions/persons/namesGen"
import {
  rolesToPersonsSet_Setup,
  rolesToPersonsSet_Person,
  rolesToPersonsSet_Persons,
} from "../../functions/persons/rolesToPersonsSet"

import {
  startOfBusinessDay,
  endOfBusinessDay,
  startOfBusinessDayISO,
  endOfBusinessDayISO,
} from "../../functions/timeHandler/bordersOfBusinessDay"

import { durStrToDur } from "../../functions/timeHandler/durations"

import locToISO from "../../functions/timeHandler/locToISO"

import nanoId from "../../functions/utils/nanoid"

import { ROOT } from "../../const/globals"

const project = (slice = [], action) => {
  //=================================================================
  let sliceNext = slice

  const { payload } = action

  let statusCurrent = sliceNext && sliceNext.statusCurrent

  if (sliceNext && sliceNext.isResetForm) {
    sliceNext = produce(sliceNext, (sliceDraft) => {
      sliceDraft.isResetForm = false
    })
  }

  if (
    sliceNext &&
    sliceNext.messageFromProject &&
    sliceNext.messageFromProject !== ""
  ) {
    sliceNext = produce(sliceNext, (sliceDraft) => {
      sliceDraft.messageFromProject = ""
    })
  }

  //================================================================
  switch (action.type) {
    // UPLOAD --------------------------------------------------------
    case projectUpload.type:
      if (payload.warning === "lowerAPI") {
        sliceNext = produce(sliceNext, (sliceDraft) => {
          sliceDraft = merge(sliceDraft, payload.project)
        })
      } else {
        // TODO: do not understand, why this cannot go via produce()???
        // It seems that you need to keep the proxy values somehow.
        sliceNext = payload.project
      }

      return sliceNext
    // RESET --------------------------------------------------------
    case resetProject.type:
      sliceNext = projectInitialStateSet()
      sliceNext.isResetForm = true
      return sliceNext

    case resetSheet.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        let theDay = isWeekend(new Date(Date.now()))
          ? addBusinessDays(new Date(Date.now()), 1)
          : new Date(Date.now())

        let _node1 = nodeCreate({
          pId: ROOT,
          fromWhen: startOfBusinessDayISO(theDay, "9"),
          byWhen: endOfBusinessDayISO(theDay, "17"),
        })

        let _root = nodeCreate({
          nId: ROOT,
          position: [],
          children: [_node1.nId],
          fromWhen: _node1.fromWhen,
          byWhen: _node1.byWhen,
        })

        sliceDraft.statuses[statusCurrent].nodes = {}
        sliceDraft.statuses[statusCurrent].nodes = {
          _root,
          [_node1.nId]: _node1,
        }
        sliceDraft.statuses[statusCurrent].rows = [ROOT, _node1.nId]

        // initial span, projection, slack
        sliceDraft.statuses[statusCurrent].nodes = nodesTreeDownUp(
          sliceDraft.statuses[statusCurrent].nodes,
          ROOT,
          statusCurrent,
          {
            doPositions: false,
            doDependenciesClean: false,
            doFromByWhens: true,
            doTrafficLights: false,
            doEarliestLatest: true,
          }
        )

        sliceDraft.isResetForm = true
      })

      return sliceNext
    // DATES --------------------------------------------------------
    case dateStatusCurrentUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statusCurrent = payload.statusCurrent
      })
      return sliceNext

    case dateStatusLatestDelete.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        const dateStatusLast = sliceDraft.statusesList[0]
        sliceDraft.statusesList.shift()
        sliceDraft.statusCurrent = sliceDraft.statusesList[0]
        delete sliceDraft.statuses[dateStatusLast]
      })
      return sliceNext

    // PERSONS --------------------------------------------------------
    case personAdd.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        const sId = nanoId()
        sliceDraft.statuses[statusCurrent].persons[sId] = personCreate(sId)
      })
      return sliceNext

    case personRemove.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        delete sliceDraft.statuses[statusCurrent].persons[payload.sId]
        sliceDraft.statuses[statusCurrent].nodes = personRemoveFromNodes(
          sliceDraft.statuses[statusCurrent].nodes,
          payload.sId
        )
        sliceDraft.statuses[statusCurrent].setup = personRemoveFromSetup(
          sliceDraft.statuses[statusCurrent].setup,
          payload.sId
        )

        sliceDraft.messageFromProject =
          "Warning: The person you removed might have been responsible for certain roles or deliverables. Please check for not assigned spaces."

        sliceDraft.isResetForm = true
      })
      return sliceNext

    case personUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusCurrent].persons[payload.sId] = payload.data

        sliceDraft.statuses[statusCurrent].persons[
          payload.sId
        ].nameDisplayShort = nameDisplayShortGen(
          payload.data.nameFirst,
          payload.data.nameLast,
          sliceDraft.params.isNameFirstPreferred
        )
        sliceDraft.statuses[statusCurrent].persons[
          payload.sId
        ].nameDisplayLong = nameDisplayLongGen(
          payload.data.nameFirst,
          payload.data.nameLast,
          payload.data.academicTitle
        )
        sliceDraft.statuses[statusCurrent].persons[payload.sId].nameInitials = (
          payload.data.nameFirst[0] + payload.data.nameLast[0]
        ).toUpperCase()

        sliceDraft.isResetForm = true
      })
      return sliceNext

    case selectPersonUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        if (payload.isNew) {
          // create a new person
          const sId = nanoId()
          sliceDraft.statuses[statusCurrent].persons[sId] = personCreate(sId)
          const names = namesGen(
            payload.personName,
            sliceDraft.params.isNameFirstPreferred
          )

          sliceDraft.statuses[statusCurrent].persons[sId].nameFirst =
            names.nameFirst
          sliceDraft.statuses[statusCurrent].persons[sId].nameLast =
            names.nameLast
          sliceDraft.statuses[statusCurrent].persons[sId].nameDisplayShort =
            names.nameDisplayShort
          sliceDraft.statuses[statusCurrent].persons[sId].nameDisplayLong =
            names.nameDisplayLong
          sliceDraft.statuses[statusCurrent].persons[sId].nameInitials =
            names.nameInitials

          // add label
          payload.value = sId
        }

        // wId is either "_setup" or it contains the nId
        if (payload.wId === "_setup") {
          sliceDraft.statuses[statusCurrent].setup[payload.name] = payload.value

          sliceDraft.statuses[statusCurrent] = rolesToPersonsSet_Setup(
            sliceDraft.rolesStandard,
            sliceDraft.statuses[statusCurrent],
            payload.name
          )
        } else {
          sliceDraft.statuses[statusCurrent].nodes[payload.wId][payload.name] =
            payload.value
        }
      })
      return sliceNext

    case selectPersonsUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        let selected = payload.selected.map((person) => {
          if (person.__isNew__) {
            const sId = nanoId()
            sliceDraft.statuses[statusCurrent].persons[sId] = personCreate(sId)
            const names = namesGen(
              person.label.trim(),
              sliceDraft.params.isNameFirstPreferred
            )

            sliceDraft.statuses[statusCurrent].persons[sId].nameFirst =
              names.nameFirst
            sliceDraft.statuses[statusCurrent].persons[sId].nameLast =
              names.nameLast
            sliceDraft.statuses[statusCurrent].persons[sId].nameDisplayShort =
              names.nameDisplayShort
            sliceDraft.statuses[statusCurrent].persons[sId].nameDisplayLong =
              names.nameDisplayLong
            sliceDraft.statuses[statusCurrent].persons[sId].nameInitials =
              names.nameInitials
            return { value: sId, label: names.nameDisplayShort }
          }
          return person
        })

        sliceDraft.statuses[statusCurrent].setup[payload.name] = selected

        let rId =
          payload.name === "membersTeam"
            ? "memberTeam"
            : payload.name === "membersStC"
            ? "memberStC"
            : ""

        let selectedMulti = selected.map((el) => ({
          rId,
          sId: el.value,
        }))

        sliceDraft.statuses[statusCurrent] = rolesToPersonsSet_Persons(
          sliceDraft.rolesStandard,
          sliceDraft.statuses[statusCurrent],
          selectedMulti,
          rId
        )
      })
      return sliceNext

    case selectRolesUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        let selected = payload.selected.map((role) => {
          if (role.__isNew__) {
            const rId = nanoId()
            const todoNew = { value: rId, label: role.label.trim() }
            if (sliceDraft.statuses[statusCurrent].rolesAdded === undefined) {
              sliceDraft.statuses[statusCurrent].rolesAdded = []
            }

            sliceDraft.statuses[statusCurrent].rolesAdded.push(todoNew)
            return todoNew
          }
          return role
        })
        let sId = payload.wId
        sliceDraft.statuses[statusCurrent].persons[sId].roles = selected

        let rolesToPersonsSelected = selected.map((el) => ({
          rId: el.value,
          sId,
        }))

        sliceDraft.statuses[statusCurrent] = rolesToPersonsSet_Person(
          sliceDraft.rolesStandard,
          sliceDraft.statuses[statusCurrent],
          rolesToPersonsSelected,
          sId
        )
      })
      return sliceNext

    case SelectRequestsUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        let selected = payload.selected.map((todo) => {
          if (todo.__isNew__) {
            const tId = nanoId()
            const todoNew = { value: tId, label: todo.label.trim() }
            sliceDraft.statuses[statusCurrent].requestsAdded.push(todoNew)
            return todoNew
          }
          return todo
        })
        sliceDraft.statuses[statusCurrent].ratios.requests = selected
      })
      return sliceNext

    // ROWS --------------------------------------------------------
    case rowCreate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusCurrent].nodes = nodeNewAddAfter(
          sliceDraft.statuses[statusCurrent].nodes,
          payload.nIdBefore
        )

        sliceDraft.statuses[statusCurrent].nodes = nodesTreeDownUp(
          sliceDraft.statuses[statusCurrent].nodes,
          ROOT,
          statusCurrent
        )

        sliceDraft.statuses[statusCurrent].rows = nodesRowsGen(
          sliceDraft.statuses[statusCurrent].nodes
        )
      })
      return sliceNext

    case rowDelete.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusCurrent].nodes = nodesDelete(
          sliceDraft.statuses[statusCurrent].nodes,
          payload.nId,
          true
        )

        sliceDraft.statuses[statusCurrent].nodes = nodesTreeDownUp(
          sliceDraft.statuses[statusCurrent].nodes,
          ROOT,
          statusCurrent
        )

        sliceDraft.statuses[statusCurrent].rows = nodesRowsGen(
          sliceDraft.statuses[statusCurrent].nodes
        )
      })
      return sliceNext

    case rowUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusCurrent].nodes[payload.nId] = {
          ...sliceDraft.statuses[statusCurrent].nodes[payload.nId],
          ...payload.data,
        }

        // Update Precedents
        if (typeof payload.data.precedentsStr !== "undefined") {
          // save in case of circular dependencies
          const nodesTemp = sliceDraft.statuses[statusCurrent].nodes

          // set new precedents and dependents
          // does not allow ancestors, children or children's children to be precedents
          sliceDraft.statuses[statusCurrent].nodes = nodesDependenciesSet(
            sliceDraft.statuses[statusCurrent].nodes,
            payload.nId,
            payload.data.precedentsStr
          )

          let seenAlready = []
          if (
            nodesTreeDepsCircularCheck(
              sliceDraft.statuses[statusCurrent].nodes,
              payload.nId,
              seenAlready
            )
          ) {
            sliceDraft.messageFromProject =
              "ERROR: You have used a circular reference, but this is not possible."
            sliceDraft.statuses[statusCurrent].nodes = nodesTemp
          } else {
            sliceDraft.statuses[statusCurrent].nodes = nodesTreeDownUp(
              sliceDraft.statuses[statusCurrent].nodes,
              ROOT,
              statusCurrent
            )
          }
        }

        // update by-when if not before project dateStart
        if (payload.data.byWhen) {
          // project dateStart
          const dateStartDate = parseISO(
            sliceDraft.statuses[statusCurrent].nodes[ROOT].fromWhen
          )

          // suggested new byWhen
          const byWhenDate = parseISO(
            locToISO(payload.data.byWhen, statusCurrent)
          )

          // byWhen cannot be before the project dateStart
          if (isBefore(byWhenDate, dateStartDate)) {
            sliceDraft.messageFromProject =
              "ERROR: BY WHEN dates cannot be before the project Start Date. You can change the project Start Date on the Setup page."
            const dateStartDayEndDate = endOfBusinessDay(
              parseISO(sliceDraft.statuses[statusCurrent].nodes[ROOT].fromWhen)
            )
            sliceDraft.statuses[statusCurrent].nodes[
              payload.nId
            ].byWhen = dateStartDayEndDate.toISOString()
          } else {
            sliceDraft.statuses[statusCurrent].nodes[
              payload.nId
            ].byWhen = byWhenDate.toISOString()

            if (
              sliceDraft.statuses[statusCurrent].nodes[payload.nId]
                .isByWhenPinned
            ) {
              sliceDraft.statuses[statusCurrent].nodes[
                payload.nId
              ].byWhenLatest =
                sliceDraft.statuses[statusCurrent].nodes[payload.nId].byWhen
            }

            sliceDraft.statuses[statusCurrent].nodes = nodesTreeDownUp(
              sliceDraft.statuses[statusCurrent].nodes,
              ROOT,
              statusCurrent
            )

            sliceDraft.isResetForm = true
          }
        }

        // accumulate spent only if change in spent
        if (payload.data.spent) {
          sliceDraft.statuses[statusCurrent].nodes[payload.nId] = {
            ...sliceDraft.statuses[statusCurrent].nodes[payload.nId],
            spent: durStrToDur(payload.data.spent),
          }
        }

        sliceDraft.statuses[statusCurrent].nodes = nodesTreeDownUp(
          sliceDraft.statuses[statusCurrent].nodes,
          ROOT,
          statusCurrent
        )

        sliceDraft.isResetForm = true
      })
      return sliceNext

    case rowIgnore.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusCurrent].nodes[
          payload.nId
        ].isIgnored = !sliceDraft.statuses[statusCurrent].nodes[payload.nId]
          .isIgnored

        sliceDraft.statuses[statusCurrent].nodes = nodesTreeDownUp(
          sliceDraft.statuses[statusCurrent].nodes,
          ROOT,
          statusCurrent
        )
      })
      return sliceNext

    case rowUnresolved.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusCurrent].nodes[
          payload.nId
        ].isUnresolved = !sliceDraft.statuses[statusCurrent].nodes[payload.nId]
          .isUnresolved

        if (
          sliceDraft.statuses[statusCurrent].nodes[payload.nId].isUnresolved
        ) {
          sliceDraft.statuses[statusCurrent].ratios.unresolvedIssues += 1
        } else {
          sliceDraft.statuses[statusCurrent].ratios.unresolvedIssues -= 1
        }

        sliceDraft.statuses[statusCurrent].nodes = nodesTreeDownUp(
          sliceDraft.statuses[statusCurrent].nodes,
          ROOT,
          statusCurrent
        )
      })
      return sliceNext

    case rowMoveIn.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusCurrent].nodes = moveIn(
          sliceDraft.statuses[statusCurrent].nodes,
          payload.nId,
          sliceDraft.statuses[statusCurrent].rows
        )

        sliceDraft.statuses[statusCurrent].nodes = nodesTreeDownUp(
          sliceDraft.statuses[statusCurrent].nodes,
          ROOT,
          statusCurrent
        )

        sliceDraft.statuses[statusCurrent].rows = nodesRowsGen(
          sliceDraft.statuses[statusCurrent].nodes
        )
      })
      return sliceNext

    case rowMoveOut.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusCurrent].nodes = moveOut(
          sliceDraft.statuses[statusCurrent].nodes,
          payload.nId
        )

        sliceDraft.statuses[statusCurrent].nodes = nodesTreeDownUp(
          sliceDraft.statuses[statusCurrent].nodes,
          ROOT,
          statusCurrent
        )

        sliceDraft.statuses[statusCurrent].rows = nodesRowsGen(
          sliceDraft.statuses[statusCurrent].nodes
        )
      })
      return sliceNext

    case rowMoveUpDown.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusCurrent].nodes = moveUpDown(
          sliceDraft.statuses[statusCurrent].nodes,
          payload.nId,
          sliceDraft.statuses[statusCurrent].rows,
          payload.isMoveUp
        )

        sliceDraft.statuses[statusCurrent].nodes = nodesTreeDownUp(
          sliceDraft.statuses[statusCurrent].nodes,
          ROOT,
          statusCurrent,
          {
            doPositions: true,
            doDependenciesClean: false,
            doFromByWhens: false,
            doTrafficLights: false,
            doEarliestLatest: false,
          }
        )

        sliceDraft.statuses[statusCurrent].rows = nodesRowsGen(
          sliceDraft.statuses[statusCurrent].nodes
        )
      })
      return sliceNext

    case rowFold.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusCurrent].nodes[
          payload.nId
        ].isFolded = !sliceDraft.statuses[statusCurrent].nodes[payload.nId]
          .isFolded

        sliceDraft.statuses[statusCurrent].rows = nodesRowsGen(
          sliceDraft.statuses[statusCurrent].nodes
        )
      })
      return sliceNext

    case rowForecast.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusCurrent].nodes[payload.nId].forecast =
          (sliceDraft.statuses[statusCurrent].nodes[payload.nId].forecast + 1) %
          5

        sliceDraft.statuses[statusCurrent].nodes = nodesTreeDownUp(
          sliceDraft.statuses[statusCurrent].nodes,
          ROOT,
          statusCurrent,
          {
            doTrafficLights: true,
            doPositions: false,
            doDependenciesClean: false,
            doFromByWhens: false,
            doEarliestLatest: false,
          }
        )
      })
      return sliceNext

    case rowQuality.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusCurrent].nodes[payload.nId].quality =
          (sliceDraft.statuses[statusCurrent].nodes[payload.nId].quality + 1) %
          5

        sliceDraft.statuses[statusCurrent].nodes = nodesTreeDownUp(
          sliceDraft.statuses[statusCurrent].nodes,
          ROOT,
          statusCurrent,
          {
            doTrafficLights: true,
            doPositions: false,
            doDependenciesClean: false,
            doFromByWhens: false,
            doEarliestLatest: false,
          }
        )
      })
      return sliceNext

    // PIN -------------------------------------------------------
    case isByWhenPinnedUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusCurrent].nodes[
          payload.nId
        ].isByWhenPinned = !sliceDraft.statuses[statusCurrent].nodes[
          payload.nId
        ].isByWhenPinned

        sliceDraft.statuses[statusCurrent].nodes[payload.nId].byWhenLatest =
          sliceDraft.statuses[statusCurrent].nodes[payload.nId].byWhen

        sliceDraft.statuses[statusCurrent].nodes = nodesTreeDownUp(
          sliceDraft.statuses[statusCurrent].nodes,
          ROOT,
          statusCurrent,
          {
            doPositions: false,
            doDependenciesClean: false,
            doFromByWhens: true,
            doEarliestLatest: true,
            doTrafficLights: true,
          }
        )
      })

      return sliceNext

    case isHighlightedUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusCurrent].nodes[
          payload.nId
        ].isHighlighted = !sliceDraft.statuses[statusCurrent].nodes[payload.nId]
          .isHighlighted
      })

      return sliceNext

    // SETTINGS -------------------------------------------------------
    case isDetailsOnUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.params.isDetailsOn = !sliceDraft.params.isDetailsOn
      })
      return sliceNext

    case isNameFirstPreferredUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.params.isNameFirstPreferred = !sliceDraft.params
          .isNameFirstPreferred
        Object.keys(sliceDraft.statuses).forEach(
          (dId) =>
            (sliceDraft.statuses[dId].persons = personsDisplayNameGen(
              sliceDraft.statuses[dId].persons,
              sliceDraft.params.isNameFirstPreferred
            ))
        )
      })
      return sliceNext

    // FORMS -------------------------------------------------------
    case formSetupUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusCurrent].setup = {
          ...sliceDraft.statuses[statusCurrent].setup,
          ...payload.data,
        }
      })

      return sliceNext

    case selectStatusUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusCurrent].setup.statusCompleted =
          payload.statusCompleted
      })
      return sliceNext

    case formDatesUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        // one can only set a new statusNext when the statusCurrent is set isCompleted
        if (
          sliceDraft.statuses[statusCurrent].setup.statusCompleted ===
            "Is Completed" &&
          payload.data.statusNext !== ""
        ) {
          let dateStatusNextDate = startOfBusinessDay(
            parseISO(locToISO(payload.data.statusNext, statusCurrent))
          )

          if (isWeekend(dateStatusNextDate)) {
            dateStatusNextDate = addBusinessDays(dateStatusNextDate, 1)
          }

          // must be at least two days after last status day
          let dateStatusCurrentPlus1 = addBusinessDays(
            parseISO(statusCurrent),
            1
          )

          if (
            !isSameDay(dateStatusNextDate, dateStatusCurrentPlus1) &&
            !isAfter(dateStatusNextDate, dateStatusCurrentPlus1)
          ) {
            sliceDraft.messageFromProject =
              "ERROR: Next status date must be at least one day after previous one."
          }
          // new Status Date accepted
          else {
            payload.data.statusNext = dateStatusNextDate.toISOString()

            sliceDraft.statuses[payload.data.statusNext] = cloneDeep(
              sliceDraft.statuses[statusCurrent]
            )

            sliceDraft.statuses[statusCurrent].setup.isStatusLocked = true
            sliceDraft.statusesList.unshift(payload.data.statusNext)

            sliceDraft.statuses[payload.data.statusNext].dId =
              payload.data.statusNext

            // from Current to Next
            sliceDraft.statusCurrent = payload.data.statusNext
            statusCurrent = payload.data.statusNext
            sliceDraft.statuses[statusCurrent].setup.isStatusLocked = false

            sliceDraft.statusNext = ""
            sliceDraft.statuses[statusCurrent].setup.statusCompleted =
              "In Progress"

            sliceDraft.messageFromProject = `Success: Current Status Date was changed to ${format(
              parseISO(statusCurrent),
              "yyyy-MM-dd"
            )}.`

            // re-calc
            sliceDraft.statuses[statusCurrent].nodes = nodesTreeDownUp(
              sliceDraft.statuses[statusCurrent].nodes,
              ROOT,
              statusCurrent,
              {
                doPositions: false,
                doDependenciesClean: false,
                doFromByWhens: true,
                doEarliestLatest: true,
                doTrafficLights: true,
              }
            )
          }
        }

        // the remaining input fields in the form

        // check if dateStart is on a weekend
        let dateStartDate = parseISO(
          locToISO(
            payload.data.dateStart,
            statusCurrent,
            true // ← startOfBusinessDay
          )
        )
        if (isWeekend(dateStartDate)) {
          dateStartDate = addBusinessDays(dateStartDate, 1)
        }

        sliceDraft.statuses[statusCurrent].nodes[
          ROOT
        ].fromWhen = dateStartDate.toISOString()

        sliceDraft.statuses[statusCurrent].nodes[
          ROOT
        ].fromWhenEarliest = dateStartDate.toISOString()

        if (payload.data.statusNextPlanned !== "") {
          // check if statusNextPlanned is on a weekend
          let dateStatusNextPlannedDate = startOfDay(
            parseISO(locToISO(payload.data.statusNextPlanned, statusCurrent))
          )
          if (isWeekend(dateStatusNextPlannedDate)) {
            dateStatusNextPlannedDate = addBusinessDays(
              dateStatusNextPlannedDate,
              1
            )
          }

          sliceDraft.statuses[
            statusCurrent
          ].statusNextPlanned = dateStatusNextPlannedDate.toISOString()
        }

        sliceDraft.statuses[statusCurrent].nodes = nodesTreeDownUp(
          sliceDraft.statuses[statusCurrent].nodes,
          ROOT,
          statusCurrent,
          {
            doPositions: false,
            doDependenciesClean: false,
            doFromByWhens: true,
            doEarliestLatest: true,
            doTrafficLights: true,
          }
        )

        if (payload.data.dayStart !== sliceDraft.params.dayStart) {
          sliceDraft.messageFromProject =
            "Warning: Day Start h and Day End h cannot be changed in this version."
        }
        if (payload.data.dayEnd !== sliceDraft.params.dayEnd) {
          sliceDraft.messageFromProject =
            "Warning: Day Start h and Day End h cannot be changed in this version."
        }
        // sliceDraft.params.dayStart = payload.data.dayStart
        // sliceDraft.params.dayEnd = payload.data.dayEnd
        sliceDraft.isResetForm = true
      })

      return sliceNext

    case formRatiosUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusCurrent].ratios = {
          ...sliceDraft.statuses[statusCurrent].ratios,
          ...payload.data,
        }
      })
      return sliceNext

    case issueCheckUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusCurrent].ratios[payload.name] = !sliceDraft
          .statuses[statusCurrent].ratios[payload.name]
      })
      return sliceNext

    case issueTLUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.statuses[statusCurrent].ratios[payload.name] =
          (sliceDraft.statuses[statusCurrent].ratios[payload.name] + 1) % 5
      })
      return sliceNext

    case formParamsUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.params.pUnresolvedIssues = payload.data.pUnresolvedIssues
          .split(",")
          .map((el) => parseInt(el))
        sliceDraft.params.pDOCThroughDOXS = payload.data.pDOCThroughDOXS
          .split(",")
          .map((el) => parseInt(el))
        sliceDraft.params.pDOCThroughDOTS = payload.data.pDOCThroughDOTS
          .split(",")
          .map((el) => parseInt(el))
        sliceDraft.params.pSatisfactionCustomer = payload.data.pSatisfactionCustomer
          .split(",")
          .map((el) => parseInt(el))
        sliceDraft.params.pSatisfactionTeam = payload.data.pSatisfactionTeam
          .split(",")
          .map((el) => parseInt(el))
      })
      return sliceNext

    // MESSAGE -------------------------------------------------------
    case messageFromProjectDelete.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.messageFromProjectDelete = ""
        sliceDraft.isResetForm = true
      })
      return sliceNext

    // DEFAULT -------------------------------------------------------
    default:
      return sliceNext
  }
}

export default project
