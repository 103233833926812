import React, { useEffect } from "react"
import { connect } from "react-redux"
import { useToast, Container, Flex, Text } from "@chakra-ui/react"
import Persons from "../../components/Persons"
import SEO from "../../components/SEO"
import { RiLockUnlockLine, RiLockLine } from "react-icons/ri"
import { messageFromProjectDelete } from "../../redux/actions/project"
import { messageFromAppDelete } from "../../redux/actions/app"

const PagePersons = ({
  isLocked,
  messageFromProject,
  messageFromApp,
  dispatch,
}) => {
  //=================================================================
  const toast = useToast()

  useEffect(() => {
    if (messageFromProject !== "") {
      const title = messageFromProject.split(": ")[0]
      const description = messageFromProject.substring(
        messageFromProject.indexOf(":") + 1
      )
      const status = title.toLowerCase()
      toast({
        title,
        description,
        status: status ? status : "info",
        duration: 9000,
        isClosable: true,
      })
      dispatch(messageFromProjectDelete())
    }
    if (messageFromApp !== "") {
      const title = messageFromApp.split(": ")[0]
      const description = messageFromApp.substring(
        messageFromApp.indexOf(":") + 1
      )
      toast({
        title,
        description,
        status: "error",
        duration: 30000,
        isClosable: true,
      })
      dispatch(messageFromAppDelete())
    }
  }, [messageFromProject, messageFromApp, toast, dispatch])

  //=================================================================
  return (
    <Container minH="640px" maxW="1200px">
      <SEO
        title="Persons | THE PROJECT STATUS"
        urlPage="/persons"
        description="Achieve transparency on all persons involved in your project by having all details in one page."
      ></SEO>
      <Flex align="center" mb={4}>
        <Text as="h1" textStyle="h2" mr={2}>
          Persons involved in the project{" "}
        </Text>
        {isLocked ? <RiLockLine /> : <RiLockUnlockLine />}
      </Flex>

      <Persons />
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    isLocked:
      state.project.statuses[state.project.statusCurrent].setup.isStatusLocked,
    messageFromApp: state.app.messageFromApp,
    messageFromProject: state.project.messageFromProject,
  }
}

export default connect(mapStateToProps)(PagePersons)
